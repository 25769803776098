.profilePic ion-thumbnail{
    width: 200px;
    height: 200px;
    --border-radius: 24px;
    position: relative;
    margin:0 auto;
}
.profilePic ion-thumbnail i{
    position: absolute;
    bottom: -20px;
    right: -20px;
    margin: 0;
    font-size: 50px !important;
    color: var(--ion-color-secondary);
    background: white;
    border-radius: 100%;
    cursor: pointer;
}
.profileContent, .profilePic{
    height: 100%;
}
.headContent h2{
    margin-top: 0 !important;
}
