/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

:root {
  --ion-color-primary: #8c1515;
  --ion-color-primary-rgb: 140,21,21;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #7b1212;
  --ion-color-primary-tint: #982c2c;

  --ion-color-secondary: #546ef9;
  --ion-color-secondary-rgb: 84,110,249;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #4a61db;
  --ion-color-secondary-tint: #657dfa;

  --ion-color-tertiary: #0c1840;
  --ion-color-tertiary-rgb: 12,24,64;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #0b1538;
  --ion-color-tertiary-tint: #242f53;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0,0,0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;

  --ion-color-medium: #828282;
  --ion-color-medium-rgb: 130,130,130;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #727272;
  --ion-color-medium-tint: #8f8f8f;

  --ion-color-light: #f2f2f2;
  --ion-color-light-rgb: 242,242,242;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d5d5d5;
  --ion-color-light-tint: #f3f3f3;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #d5d5d5;
  --ion-color-white-tint: #f3f3f3;

  --ion-default-font: 'SF Pro Display'!important;

  --head-text: #222B36;
  --faint-text-color: #8A9095;
}

.white{
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #d5d5d5;
  --ion-color-white-tint: #f3f3f3;
}
/* @media (prefers-color-scheme: dark) {


  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }


  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}*/
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'SF UI Display Ultralight';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Ultralight'), url('/assets/fonts/sf-ui-display-ultralight-58646b19bf205.woff') format('woff');
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('/assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('/assets/fonts/SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
font-family: 'SF UI Display Thin';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Thin'), url('/assets/fonts/sf-ui-display-thin-58646e9b26e8b.woff') format('woff');
}

@font-face {
font-family: 'SF UI Display Light';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Light'), url('/assets/fonts/sf-ui-display-light-58646b33e0551.woff') format('woff');
}

@font-face {
font-family: 'SF UI Display Medium';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Medium'), url('/assets/fonts/sf-ui-display-medium-58646be638f96.woff') format('woff');
}

@font-face {
font-family: 'SF UI Display Semibold';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Semibold'), url('/assets/fonts/sf-ui-display-semibold-58646eddcae92.woff') format('woff');
}

@font-face {
font-family: 'SF UI Display Bold';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Bold'), url('/assets/fonts/sf-ui-display-bold-58646a511e3d9.woff') format('woff');
}

@font-face {
font-family: 'SF UI Display Heavy';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Heavy'), url('/assets/fonts/sf-ui-display-heavy-586470160b9e5.woff') format('woff');
}

@font-face {
font-family: 'SF UI Display Black';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Black'), url('/assets/fonts/sf-ui-display-black-58646a6b80d5a.woff') format('woff');
}




html, body{
  font-family: 'Poppins', sans-serif !important;
  font-family: var(--ion-default-font) !important;
  letter-spacing: 0.3px;
  background: #e6e6e6;
}
.d-a{
  display: flex;
  align-items: center;
}
/* custom */
.header-md:after{
  display: none;
}
.header-ios ion-toolbar:last-of-type{
  --border-width: 0;
}
.ion-page{
    max-width: 475px;
    margin: 0 auto;
}
ion-button{
    min-height: 40px;
    --border-radius: 30px;
    font-size: 16px;
    letter-spacing: 0.4px;
    font-weight: 400;
}

p{font-size: 14px !important;color:var(--ion-color-medium);}
ion-tabs {
  border: solid #dedede;
  border-width: 0 1px 0 1px;
}
p.dangerMessage{
  color: #F44336 !important;
}
p.successMessage{
  color: #4CAF50 !important;
}
.head-text-color{
  color: #222B36 !important;
}
.faint-gray-color{
  color: #8A9095;
}
/* @media(min-width:500px){
  ion-header{
    display: none;
  }
} */

/*  ion-header{
    display: none;
  } */
.ios ion-searchbar{height: 48px;}
.ios ion-searchbar input{font-size: 14px !important;}

.font-light{font-family: 'SF UI Display Light';}
.font-regular{font-family: 'SF Pro Display';}
.font-medium{font-family: 'SF UI Display Medium';}
.font-semiBold{font-family: 'SF UI Display Semibold';}
.font-bold{font-family: 'SF UI Display Bold';}

ion-content .headTitle {
    margin-top: 10px;
}
.d-inline-flex{
  display: inline-flex;
}


:root {
  --card-padding: 24px;
  --card-height: 340px;
  --card-skeleton: linear-gradient(white var(--card-height), transparent 0);
  --avatar-size: 32px;
  --avatar-position: var(--card-padding) var(--card-padding);
  --avatar-skeleton: radial-gradient(circle 16px at center, #eaeaea 99%, transparent 0);
  --title-height: 32px;
  --title-width: 70%;
  --title-position: var(--card-padding) 150px;
  --title-skeleton: linear-gradient(#eaeaea var(--title-height), transparent 0);
  --desc-line-height: 16px;
  --desc-line-skeleton: linear-gradient(#eaeaea var(--desc-line-height), transparent 0);
  --desc-line-1-width: 90%;
  --desc-line-1-position: var(--card-padding) 200px;
  --desc-line-2-width: 40%;
  --desc-line-2-position: var(--card-padding) 230px;
  --footer-height: 40px;
  --footer-position: 0 calc(var(--card-height) - var(--footer-height));
  --footer-skeleton: linear-gradient(white var(--footer-height), transparent 0);
  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(var(--card-height) - var(--footer-height));
}

.cardSkeleton {
  width: calc(100% - 20px);
  height: var(--card-height);
  margin: 10px;
}

.cardSkeleton:empty::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0 10px 45px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgb(255 255 255 / 22%)), color-stop(50%, rgba(211, 211, 211, 0.8)), to(rgba(211, 211, 211, 0))),
  var(--title-skeleton),
  var(--desc-line-skeleton),
  var(--desc-line-skeleton),
  var(--avatar-skeleton),
  var(--footer-skeleton),
  var(--card-skeleton);
  background-image: linear-gradient(90deg, rgba(211, 211, 211, 0) 0, rgb(255 255 255 / 80%) 50%, rgba(211, 211, 211, 0) 100%),
  var(--title-skeleton),
  var(--desc-line-skeleton),
  var(--desc-line-skeleton),
  var(--avatar-skeleton),
  var(--footer-skeleton),
  var(--card-skeleton);
  background-size: var(--blur-size), var(--title-width) var(--title-height), var(--desc-line-1-width) var(--desc-line-height), var(--desc-line-2-width) var(--desc-line-height), var(--avatar-size) var(--avatar-size), 100% var(--footer-height), 100% 100%;
  background-position: -150% 0, var(--title-position), var(--desc-line-1-position), var(--desc-line-2-position), var(--avatar-position), var(--footer-position), 0 0;
  background-repeat: no-repeat;
  -webkit-animation: loadingSkel 1.5s infinite;
  animation: loadingSkel 1.5s infinite;
}

@-webkit-keyframes loadingSkel {
  to {
    background-position: 350% 0, var(--title-position), var(--desc-line-1-position), var(--desc-line-2-position), var(--avatar-position), var(--footer-position), 0 0;
  }
}

@keyframes loadingSkel {
  to {
    background-position: 350% 0, var(--title-position), var(--desc-line-1-position), var(--desc-line-2-position), var(--avatar-position), var(--footer-position), 0 0;
  }
}

ion-tab-bar ion-tab-button ion-icon{font-size: 20px;}
ion-tab-bar ion-tab-button.tab-selected{color: var(--ion-color-dark);}
ion-tab-bar ion-tab-button {
  color: #BEC0D7;
}


.skeleton{
  position: relative;
  display: block;
  background: #f1f1f1;
  overflow: hidden;
}
/* .skeleton:before{
  position: absolute;
  -webkit-animation-name: aniUISkeleton;
  animation-name: aniUISkeleton;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background: linear-gradient(135deg, transparent 0, rgba(255, 255, 255, 0.45) 50%, transparent 100%);
  background-size: 50%;
  width: 100%;
  height: 100%;
  content: "";
  
} */

.skeleton:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 1s infinite;
  content: '';
}
@keyframes aniUISkeleton{0%{background-position:-100% 0}to{background-position:100% 0}}
@-webkit-keyframes aniVertical{0%{opacity:0}50%{opacity:1}to{opacity:0}}
@keyframes aniVertical{0%{opacity:0}50%{opacity:1}to{opacity:0}}

 @keyframes shimmer {100% {transform: translateX(100%);}}

 /* .plt-desktop .homePageNew ion-title{padding-left: 70px;} */

 .birthday__enter__Page ion-select{
   min-width: 100%;
 }
  .birthday__enter__Page ion-item{
    --padding-start: 0;
    --inner-padding-end: 0;
    background: transparent;
    --background: transparent;
    border: 1px solid rgb(190 192 215 / 0.65);
    --border-width: 0;
  }
  .birthdaySpacer{min-height: 40vh;display: flex;flex-direction: column;justify-content: space-between;}
  .birthday__listing ion-list-header {
    font-size: 18px;
    height: 50px;
    align-items: center;
    border-bottom: 1px solid rgb(204 204 204 / 33%);
    padding-left: 0;
    font-family:'SF UI Display Bold';
    font-weight: normal !important;
  }
.birthday__listing ion-list{
  padding: 0 12px;
}
.birthday__listing ion-list ion-item{
  --padding-start: 0;
  --inner-padding-end: 0;
  border-bottom: 1px solid rgb(204 204 204 / 33%);
  border-radius: 0;
  margin: 0;
  color: var(--ion-color-tertiary);
  min-height: 60px;
}
.birthday__listing ion-list ion-item p{
  font-family: 'SF Pro Display' !important;
  color: #8A9095 !important;
}
.birthday__shout__icon{
  width: 32px;height: 32px;
}
ion-refresher,
.refresher-refreshing,
ion-refresher-content {
  background: white;
  --background: white;
}

.home__refresher,
.home__refresher ion-refresher-content,
.home__refresher .refresher-refreshing {
  background: transparent;
  --background: transparent;
  color: white;
  --color: white;
}
.home__refresher .refresher-pulling-icon,
.home__refresher .refresher-pulling-text,
.home__refresher .refresher-refreshing-icon,
.home__refresher .refresher-refreshing-text
{color: white}

.comment__span {
  align-items: center;
  display: flex;
  cursor: pointer;
}
.comment__span img{
  margin-right: 7px;
}
ion-progress-bar{
  min-height: 3px;
}
.ios .image__picker__home .action-sheet-container .action-sheet-group {
      margin: 3px !important;
      border-bottom-left-radius: 13px;
      border-bottom-right-radius: 13px;
}