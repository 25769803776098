.givesomeImage{
    width: 132px;
    height: 132px;
    border-radius: 100%;
    display: block;
    margin: 0 auto;
    border: 2px solid var(--ion-color-primary);
    padding: 3px;
}
.givesomeImage img{
    width: 100%;
    height:100%;
    object-fit: cover;
    border-radius: 100%;
}
.givesomePage ion-item {
    --background: #F3F3F3;
    background: #F3F3F3;
    margin: 0 15px;
    --padding-start: 5px;
    --padding-end: 5px;
}
.givesomePage ion-item ion-textarea textarea{ 
    min-height: 150px;
    padding: 5px !important;
    font-size: 15px !important;
    line-height: 1.5;
    font-family: 'SF Pro Display';
    color: #222B36;
}

#_capacitor-camera-input { opacity: 0;}
.photoName {padding: 0 15px;}
.photoName p i{margin:0px !important;    font-size: 22px !important;color: #A7AAAF;}
.photoButton {font-size: 16px;font-weight: 500;}
.photoButton i{margin-right:5px !important;color: var(--ion-color-secondary);}