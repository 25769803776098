
/* New Search Code 28-08-2020 */ 

.headTitle{
    /*padding: 0 10px;*/
    font-size: 22px !important;
    color: #38404A;
    /* letter-spacing: 0.4px; */
    /*margin: 10px 0;*/
    font-family: 'SF UI Display Bold';
    font-weight: normal !important;
}
/* .ios .headTitle{
     padding-left: 45px;
     padding-right: 45px;
} */
.searchPage ion-item{
    --background: transparent;
    background: transparent;
}
.searchPage ion-item ion-label h4{font-size: 15px !important;font-weight: normal !important;padding-left:0px ;}

/* New Search Code 28-08-2020 */ 
.pad-x-15{padding-left: 15px ;padding-right: 15px ;}

.ourClass ion-item{
    --background:transparent !important;
    --inner-border-width:0px;
    border-bottom: 1px solid var(--ion-color-light);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.ourClass ion-item:last-child{
    border-bottom:0px;
}
.ourClass ion-item h5{
    font-size: 16px !important;
    font-family: 'SF UI Display Medium';
    font-weight: normal !important;
}
ion-thumbnail{
    --border-radius: 10px;
}
.ourClass ion-item i{
    color:var(--ion-color-primary);
    margin: 0px !important;
}
.d-w-c{
    display: block;
    text-align: center;
    width: 100%;
}
.my__class__header ion-title.px-15 {
    padding-left: 15px;
}

.listAvatar{
    width: 42px;height: 42px;border-radius: 100%;margin-right: 14px;
}
.listName{height: 10px;width: 70%;border-radius: 3px;}
.my__class__header ion-buttons.filter__btn {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 100%;
    background: #EFEFF0;
    margin-right: 12px;
}
.my__class__header ion-buttons.filter__btn ion-button {
    width: 40px;
    height: 40px;
    min-width: 40px;
    margin: 0;
}
/* .my__class__header ion-title{padding-left: 40px;} */
.my__class__items{margin-bottom: 6px;}

.filter__selected {
    display: flex;
    align-items: center;
    border-radius: 30px;
    background: rgb(210 108 108 / 25%);
    color: #B15858;
    padding: 6px 8px 6px 12px;
    justify-content: space-between;
    margin: 0 5px;
}
.filter__selected:first-child{
    margin-left: 0;
}
.filter__selected .filter__close {
    margin-bottom: 0;
    background: #D26C6C;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
}
.filter__selected .filter__close i{font-size: 14px !important;margin: 0;color: white;}
.filter__modal{
    align-items: flex-end;
}
.filter__modal .modal-wrapper {
    height: 200px;
    bottom: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    min-height: 200px;
    position: relative;
}
.filter__modal .modal-wrapper .ion-page{
    /* padding: 15px 0; */
    justify-content: center;
}
.checkBox__Heading{
    font-size: 18px;
    font-family: 'SF UI Display Semibold';
    color: var(--ion-color-tertiary);
    padding: 15px;
}
.filter__modal .modal-wrapper .ion-page ion-item{
    background: transparent;
    --background: transparent;
    --padding-start: 15px;
    --inline-padding-end: 15px;
    height: 46px;
}
.filter__modal .modal-wrapper .ion-page ion-item ion-checkbox{
    margin-right: 16px !important;
    --background: #EFEFF0;
    --border-width: 0;
    --size: 20px;
    --border-radius: 3px;
    font-size: 16px !important;
   --detail-icon-font-size: 14px;
   margin: 0;

}
.filter__modal .modal-wrapper .ion-page ion-item ion-label{
    font-size: 16px;
    color: var(--ion-color-tertiary);
}
.font20{font-size: 20px !important;}
.mr-5px{margin-right: 5px;color: inherit;}
.p-8{padding: 8px;}
.no-padding-bottom {
    padding-bottom: 0px !important;
}
.dash__span{
    width: 30px;
    height: 4px;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: #e2e2e2;
}
/* .fixed__btn__close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 200px);
    background: transparent;
    --background: transparent;
} */