ion-avatar.profile{
    width: 100px;
    height: 100px;
    --border-radius: 20px;
    margin-right:30px;
    position: relative;
}
ion-avatar i{
    position: absolute;
    bottom: -9px;
    right: -12px;
    margin: 0;
    font-size: 30px !important;
    color: var(--ion-color-secondary);
    background: white;
    border-radius: 100%;
    cursor: pointer;
}
.profileItem{
    --background: transparent;
    border:none;
    --inner-border-width:0;
}

i{
    color:var(--ion-color-tertiary);
    margin-right: 16px;
    font-size:30px !important;
}
h4{
    font-size:20px!important;
    font-weight:600 !important;
    color:var(--ion-color-dark);
}
h3{
    font-size:20px!important;
    font-weight:500 !important;
    color:var(--ion-color-medium);
    margin: 25px 0 !important;
}
ion-item-divider{
    min-height: 1px;
}
.headContent p{
    font-size: 16px;
    color: var(--ion-color-tertiary);
    font-weight: 500;
}

/* .prifileGrid{
    padding:15px;
} */