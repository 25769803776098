.DNB{display: inline-block;--border-radius:0;height: auto;max-width: 50px;}
.home-spacer{padding: 0px 15px;}
@media(max-width:350px){
    .home-spacer{
        padding: 0;
    }
}
h2{
    font-size: 24px !important;
    font-weight: 600 !important;
}

ion-item{
    /* border: 1px solid var(--ion-color-light); */
    --background: var(--ion-color-light) ;
    --border-radius: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    --inner-border-width: 0;
    border: 1px solid transparent;
    border-radius: 10px;
}
ion-item.item-has-focus{
    border-color: var(--ion-color-primary);
    --highlight-background:none;
}
.home-spacer ion-item{
    --background: transparent !important;
    --border-width: 1px;
    --border-color: rgb(190 192 215 / 0.65);
    border: none;
}
.home-spacer ion-item.item-has-focus{--border-width:2px;--border-color: var(--ion-color-primary);}
.loginGrid{
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.loginGrid .logBottom{width: 100%;}
ion-input{font-size: 16px;}

