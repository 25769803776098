.youlikeText textarea{
    min-height: 200px;
}
.textItem{
    margin-top:30px !important;
}
.labelWithIcon{
    display: flex;
    align-items: center;
}
.labelWithIcon i{
    margin-right: 10px;
    color: var(--ion-color-primary) !important;
}
.Dblock{
    display: block;
}
ion-label{
    font-size: 14px;
    font-weight: 500;
}