.primary-color{color: var(--ion-color-primary);}
.dark-color{color: #222B36;}
.d-flex{display: flex;}
.homePageNew{
    font-family: 'SF UI Display Medium';
    background: #f5f7fc;
}
.homePageNew:after{
    width: 100%;
    height: 200px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #CD5C5C;
    content: '';
    position: absolute;
    top: 0; left: 0;
}
.transparent{background: transparent;--background: transparent;}
.homePageNew ion-toolbar {
    padding-left: 7px;
    padding-right: 7px;
}


.homePageNew ion-title{color: #ffffff;font-size: 22px;font-family: 'SF UI Display semiBold';font-weight: normal;}
.homePageNew ion-button {--border-radius: 30px;text-transform: capitalize;}
.homePageNew ion-button i {color: #ffffff;}

.homePageNew .header-background{backdrop-filter: none;}

.homePageNew ion-content{z-index: 9;    --padding-start: 8px; --padding-end: 8px;}
.homePageNew ion-card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgb(18 37 85 / 0.12);
    margin: 4px 4px 16px 4px !important;
}
.homePageNew ion-card ion-card-title{ display: flex; align-items: center;}
.homePageNew ion-card-content p{font-size: 14px;line-height: 1.5;margin-bottom: 10px;}
.imgDiv{margin: 8px -20px;}
.imgDiv img{width: 100% !important;}

.secondUser {width: 32px;height: 32px;border-radius: 100%;}
.secondUser img {width: 100%;height: 100%;object-fit: cover;border-radius: 100%;}

.primaryUser {width: 48px;height: 48px;border-radius: 100%;border: 2px solid var(--ion-color-primary);padding: 4px;background: #ffffff;margin-left: -10px !important;}
.primaryUser img {width: 100%;height: 100%;object-fit: cover;border-radius: 100%;}

.userImages {display: inline-flex;align-items: center;}
.userImages span{margin: 0px;}
.userNames {display: inline-flex;padding-left: 10px;flex-direction: column;justify-content: flex-start;width: 100%;}

.userNames .userShoutName, .userNames h4, .userNames span {margin: 0;}
.userNames .userShoutName{display: flex;width: 100%;font-size: 15px !important;margin-bottom: 3px;flex-direction: row;flex-wrap: wrap;}

.userNames h4{font-size: 16px !important;font-weight: normal !important;}

.homePageNew ion-card-content .likedUser i {
    font-size: 22px !important;
    margin: 0px;
    color: #8A9095;
    cursor: pointer;
    min-width: 22px;
}
.homePageNew ion-card-content .likedUser p{font-size: 12px !important;color: #8A9095;line-height: 1;padding-left: 10px;margin-bottom: 0px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}

.homePageNew ion-card-content .likedUser.liked i, .homePageNew ion-card-content .likedUser.liked p{color: var(--ion-color-primary);}
@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) {

    }
.homeHead img{max-width: 25px;}
.whiteSpace{
    white-space: pre-line;
}
.todays__birthday .birthday__card {
    padding: 10px;
    border-radius: 8px !important;
    margin: 0px 8px 0px 4px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 270px;
    box-shadow: 0 4px 16px rgb(18 37 85 / 0.12) !important;
}
.todays__birthday .birthday__card:only-child{
    width: 100%;
    min-width: 98%;
}

.commentIcon {
    background: rgb(255 255 255 / 22%);
    border-radius: 100%;
    height: 45px !important;
    width: 45px;
    color: #ffffff;
}
.commentIcon ion-icon{
    margin: 0px !important;
}
.commentIcon ion-badge {
    position: absolute;
    top: 2px;
    right: 1px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    text-align: center;
    padding: 0;
    line-height: 20px;
    border-radius: 100%;
}
.postBottomSection span {
    font-size: 12px !important;
    color: #8A9095;
    text-decoration: none;
    margin: 0;
}
.max60{max-width: 60%;}

.commentModal{align-items: flex-end;}

.commentModal .ion-page{
    justify-content: unset;
}

.commentModal .modal-wrapper {
    height: 90vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.commentModal ion-item {
    --padding-start: 0px;
    --padding-inline-end: 0px;
    padding-inline-end: 0 !important;
    align-items: flex-start;
    padding: 10px;
    margin-bottom: 0;
    background: transparent;
    --background: transparent;
}
.commentModal ion-item.commentReplyItem{
    padding-left: 62px;
}
.commentSection {
    overflow-y: auto;
    padding-bottom: 60px;
    /* height: calc(90vh - 55px - 41px); */
}

.commentModal ion-item ion-label{
    margin-top: 0;
    margin-bottom: 0;
}
.commentDisplayName{
    padding: 5px 0;
    border-bottom: 1px solid #E5E5E5;
    position: sticky;
    top: 0;
}
.commentDisplayName .commentUN{
    width: 100%;
    text-align: center;
    font-size: 14px !important;
    
}

.commentModal ion-item ion-thumbnail{
    width: 40px;
    height: 40px;
    --border-radius: 100%;
    margin-right: 12px;
    margin-top: 0;
}
.commentModal ion-item.commentReplyItem ion-thumbnail{
    width: 32px;
    height: 32px;
    margin-top: 0;
}
.commentModal ion-item .commentUHead{
    margin-bottom: 5px;
}
.commentModal ion-item .commentUN{
    font-size: 14px !important;
    color: var(--head-text);
    text-transform: capitalize;
}
.commentModal ion-item .timeDot{
    width: 3px;height: 3px;display: block;border-radius: 10px;background: var(--faint-text-color);margin: 0 7px;
}
.commentModal ion-item .timeSpan {
    font-size: 12px !important;
    color: var(--faint-text-color);
    margin: 0;
}
.commentModal ion-item .commentText{
    font-size: 14px;color: var(--head-text);
    text-overflow: initial;
    overflow: unset;
    white-space: pre-line;
    line-height: 1.4;
    margin-bottom: 10px;
}
.commentModal ion-item .commentLikes{
    color: var(--faint-text-color);
}
.commentModal ion-item .commentLikes i{
    font-size: 10px !important;
    margin-right: 0;
    margin-left: 5px;
    
}
.commentModal ion-item .commentActionText span{
    color: var(--faint-text-color);
    text-decoration: none;
    margin-bottom: 0;
    cursor: pointer;
}
.commentFooter{
    display: flex;
    align-items: center;
    padding: 7px;
    border-top: 1px solid #E5E5E5;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    z-index: 9;
    height: 55px;
}
.commentInput {
    width: auto;
    display: flex;
    align-items: center;
    flex: auto;
}
.commentInput ion-textarea{
    background-color: rgb(190 192 215 / 25%);
    border-radius: 7px !important;
    height: 40px;
    margin: 0;
    overflow: hidden;
}
.commentInput ion-textarea textarea {
    /* padding-inline-start: 10px !important; */
    /* height: 40px; */padding: 0px !important;
}
.commentInput ion-textarea .textarea-wrapper {
    padding: 10px;
    height: 40px;
}
.commentInput ion-button {
    padding: 0px !important;
    width: 40px;
    height: 40px;
    --padding-bottom: 0;
    --padding-end: 0;
    --padding-start: 0;
    --padding-top: 0;
}
.commentOptions {
    display: flex;
}
.commentOptions ion-buttons {width: 40px;height: 40px;}
.commentOptions ion-buttons i{margin: 0;color: var(--faint-text-color);}
.birthday__user{
    width: 44px;
    height: 44px;
    border-radius: 100%;
    border: 2px solid #fff;
    margin-right: 10px;
}
.shouting__pan {
    width: 36px;
    height: 36px;
    border: 2px solid #fff;
    margin-left: 10px;
}
.b__user__name {
    font-size: 15px;
    font-weight: normal !important;
    font-family: 'SF Pro Display';
    color: var(--ion-color-tertiary);
}
.todays__birthday{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    position: relative;
    margin: 0 -8px;
    padding: 0 8px 12px ;
}
.birthday__head p{
    font-size: 16px !important;
    color: var(--ion-color-white);
}
.birthday__head ion-button {
    min-height: auto;
    height: 20px;
    margin: 0;
}
.birthday__head{
    margin-bottom: 8px;
}
.action-sheet-container {
    padding: 0 !important;
}
.action-sheet-container .action-sheet-group{
    margin: 0 !important;
    
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.blank__spacer__dob{
    width: 10px;
    height: 50px;
    margin: 0;
    display: block;
    min-width: 5px;
}
.view__all__btn{
    font-size: 16px;
    margin: 0;
    color: white;
}
.head__break{
    min-height: 60px;
    --min-height: 60px;
    display: flex;
    align-items: center;
}
.fixed__image{
    background: white;
    box-shadow: 0 0 15px rgb(0 0 0 / 17%);
    position: fixed;
    bottom: 55px;
    width: 100%;
    padding: 7px;
}
.fixed__image ion-avatar.profile {
    width: 100px;
    height: 100px;
    --border-radius: 10px;
    margin-right: 10px;
    position: relative;
    border: 1px solid #ccc;
    margin-bottom: 0 !important;
    display: inline-block;
}
.fixed__image ion-avatar.profile i{
    position: absolute;
    top: 5px;
    display: flex;
    align-items: center;
    height: 20px;
    border-radius: 10px;
    background: #404040;
    width: 20px;
    font-size: 21px !important;
    color: white;
    right: 5px;
}
.border-1-faint{
    border: 1px solid #e6e6e6;
}
.gif__space {
    position: fixed;
    bottom: -275px;
    padding: 7px;
    height: 220px;
    width: 100%;
    background: white;
    box-shadow: 0 0 15px #0000002e;
    transition: all ease 0.3s;
}
.gif__space.activeted__gif{
    bottom: 55px;
    transition: all ease 0.3s;
}
.gif__flex {
    display: flex;
    overflow-y: auto;
    max-height: 220px;
}
.gif__50{
    width: 50%;
    border-right: 2px solid white;
}
.gif__height {
    max-height: 80px;
    object-fit: cover;
    overflow: hidden;
}
.gif__height img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.gif__50:last-child {border: none;}
.gif__50 img{width: 100%;cursor: pointer;}


/* .commentModal ion-content {
    max-height: calc(90vh - 55px - 41px);
} */
