.youlikeText textarea{
    min-height: 200px;
}
.textItem{
    margin-top:30px !important;
}
ion-item.textItem ion-textarea textarea{ 
    min-height: 150px;
    padding: 5px !important;
    font-size: 15px !important;
    line-height: 1.5;
    font-family: 'SF Pro Display';
    color: #222B36;
}
ion-item.textItem {
    --background: #F3F3F3;
    background: #F3F3F3;
    margin: 0 15px;
    --padding-start: 5px;
    --padding-end: 5px;
}