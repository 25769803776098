ion-avatar.profile{
    width: 100px;
    height: 100px;
    --border-radius: 20px;
    margin-right:30px;
    position: relative;
    margin-bottom: 8px !important;
}
ion-avatar i{
    position: absolute;
    bottom: -9px;
    right: -12px;
    margin: 0;
    font-size: 30px !important;
    color: var(--ion-color-secondary);
    background: white;
    border-radius: 100%;
    cursor: pointer;
}
.profileItem{
    --background: transparent;
    border:none;
    --inner-border-width:0;
}
.profilePage .profileItem{
    --inner-border-width: 0;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;
}
h6{
    font-size:16px!important;
    font-weight:600 !important;
    color:var(--ion-color-tertiary);
    margin-bottom: 5px !important;
}
h5{
    font-size:18px!important;
    font-weight:600 !important;
    color:var(--ion-color-tertiary);
}
i{
    color:var(--ion-color-tertiary);
    margin-right: 16px;
    font-size:30px !important;
}
h4{
    font-size:20px!important;
    font-weight:600 !important;
    color:var(--ion-color-dark);
}
h3{
    font-size:20px!important;
    font-weight:500 !important;
    color:var(--ion-color-medium);
    margin: 25px 0 !important;
}
ion-item-divider{
    min-height: 1px;
}
.headContent p{
    font-size: 15px !important;
    color: var(--ion-color-medium) !important;
    font-weight: 400 !important;
}
span{
    color: var(--ion-color-tertiary);
    font-weight:400;
    margin-bottom:15px;
    display: block;
    font-size: 15px;
}
.prifileGrid{
    padding:0 15px;
}
.headTT h5 span{font-size: 16px;font-family: 'SF UI Display Semibold';font-weight: normal;}
.headTT h5 span i {
    font-size: 22px !important;
    margin-right: 12px;
    width: 22px;
}
.headTT h5{margin-top: 0px}
.profilePage ion-buttons ion-button i {font-size: 20px !important;color: var(--ion-color-medium); }
.profilePage .headContent p{color: var(--ion-color-tertiary) !important;}
.line16{
    line-height: 1.6;
}
.prifileGrid .headContent p{
    color: var(--ion-color-tertiary) !important;
}

.userSkel{width: 100px;height: 100px;border-radius: 15px;}
.userNameSkel{width:calc(100% - 116px);height: 20px;border-radius: 5px;}
.IconSkel{width: 30px;height: 30px;border-radius: 100%;}
.IconNameSkel{width: calc(100% - 46px);height: 15px;border-radius: 3px;}
.mb-10{margin-bottom: 10px;}
.d-row {display: flex;flex-wrap: wrap;}
.paraSkel .IconNameSkel {width: calc(100% - 46px);height: 10px;}
.buttonSkel{height: 40px;width: 100%;border-radius: 30px;}
.profile__ui__changes .headContent{margin-bottom: 0px;}
.profile__ui__changes .headContent h5,
.profile__ui__changes .headContent p {margin: 0;}
.profile__ui__changes .headContent h5 span {
    font-size: 14px;
    font-family: 'SF UI Display Bold';
}
.profile__ui__changes .headContent h5{
    min-height: 40px;
}

.profile__ui__changes .headContent h5 span i{
    font-size: 24px !important;
    margin-right: 10px;
    width: 24px;
    color: #D26C6C !important;
}
.profile__ui__changes .headContent p{
    padding-left: 34px;
}
.exp__details {
     padding-left: 34px;
     margin: 0;
}
.exp__details h6{
    font-size: 14px !important;
    margin: 0;
}
.exp__detail span{
    margin-bottom: 0;
}